.table__style {
  overflow: auto;
  .table {
    border-radius: 10px;
    tbody {
      .phonenumber-click {
        color: inherit;
        text-decoration: inherit;
        display: inline-block;
        border: none;
        background: transparent;
        cursor: pointer;
        &:active {
          transform: scale(0.9);
        }
      }
      tr {
        .action-table-data {
          display: flex;
          justify-content: center;
        }
        .transfer-table-data {
          display: flex;
          justify-content: center;
        }
        .no-user-found {
          text-align: center;
        }
      }
    }
  }

  .phone_user_click {
    border: none;
    background: transparent;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  .product-wrapper {
    text-align: center;
    vertical-align: middle;

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    .product-image {
      width: 5rem;
      height: auto;
    }
  }
}

.company-class{
  .table__style {
    overflow: auto;
    .table {
      border-radius: 10px;
      thead{
        tr {
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }
}

.machine-class{
  .table__style {
    overflow: auto;
    .table {
      border-radius: 10px;
      thead{
        tr {
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
}

.ticket-class{
  .table__style {
    overflow: auto;
    .table {
      border-radius: 10px;
      thead{
        tr {
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(6) {
            text-align: center;
          }
        }
      }
    }
  }
}

.usercompany-class{
  .table__style {
    overflow: auto;
    .table {
      border-radius: 10px;
      thead{
        tr {
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
}