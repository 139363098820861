@import "~bootstrap/scss/bootstrap";
@import "./variable.scss";

.App {
  width: 100%;
}
.kliktombol:hover{
  cursor: pointer;
}
.sales-container{
  background: $primary;
  color: white;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 10px 1em;
}
.sales-container p{
  margin-bottom: 0;
}
.button-cs{
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  padding: 10px 0;
}
.form-group{
  min-height: 90px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.ProductDashboard {
  background-color: transparent;
  padding: 0.8em 0.8em;
  .sales-detail-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid grey;
    background: $primary;
    color: white;
    border-radius: 5px;
    margin-bottom: 1em;
    padding: 10px 1em;
    .sales-detail {
      p {
        margin-bottom: 0;
      }
    }
    .button-create-sales-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button1 {
        margin-bottom: 10px;
      }
      .button2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .search_wrap {
    background-color: #e6e5ea;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .search_wrap input {
    background-color: transparent;
    border: none;
    padding: 0.2em 1em;
    outline: none;
    width: 80%;
    padding-right: 0;
  }
}
.up, .down{
  border: none;
  color: black;
  background-color: transparent;
}

.LoginPage {
  height: 100vh;
  display: flex;
  align-items: center;
}
.select-category {
  padding-bottom: 0.5rem;
  display: inline-block;
  width: auto;
}
.width-card{
  width: 100%;
}
.select-locations{
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  padding-bottom: 10px;
}
.sales-chart{
  padding-bottom: 20px;
}
.total{
  text-align: center;
  font-size: clamp(18px, -1.5rem + 8vw, 2.5rem);
  font-weight: bold;
  margin: 0 auto;
  display: block;
}
.center-align{
  text-align: center;
}