.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorClass{
  margin-bottom: -20px;
}
.formClass{
  width: 100%;
}